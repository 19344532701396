import axios from '@/common/axios'
import qs from 'qs'

export function addRole (data) {
  return axios({
    method: 'post',
    url: '/role/add',
    data: qs.stringify(data)
  })
}

export function deleteRole (id) {
  return axios({
    method: 'delete',
    url: '/role/delete/' + id
  })
}

export function updateRole (data) {
  return axios({
    method: 'put',
    url: '/role/update',
    data: qs.stringify(data)
  })
}

export function selectRoleInfo (id) {
  return axios({
    method: 'get',
    url: '/role/info/' + id
  })
}

export function selectRoleList (query) {
  return axios({
    method: 'get',
    url: '/role/list',
    params: query
  })
}
